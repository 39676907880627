import { template as template_4594de8a4cff4af2bb6b71055dc796fa } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_4594de8a4cff4af2bb6b71055dc796fa(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_4594de8a4cff4af2bb6b71055dc796fa(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_4594de8a4cff4af2bb6b71055dc796fa(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
